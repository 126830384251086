/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import MDXLayout from "/builds/thiagovilla/thiagovilla.com/src/templates/blog";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li",
    div: "div",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Topic:")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Goal:")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Persona:")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Research:")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Outline:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "CRUD routes with verb in the name, not HTTP verb (createOrder vs POST /orders)"), "\n", React.createElement(_components.li, null, "RPC command with GET, not POST; query not with GET"), "\n"), "\n", React.createElement("hr"), "\n", React.createElement(_components.p, null, "xxx"), "\n", React.createElement(_components.p, null, "wrong"), "\n", React.createElement(_components.div, {
    className: "remark-highlight"
  }, React.createElement(_components.pre, {
    className: "language-unknown"
  }, React.createElement(_components.code, {
    className: "language-unknown"
  }, "router.post(&#39;createOrder&#39;, (req, res) =&gt; {\n// ...\n})\n\nrouter.get(&#39;/shipOrder/:id&#39;, (req, res) =&gt; {\n// ...\n})"))), "\n", React.createElement(_components.p, null, "right"), "\n", React.createElement(_components.div, {
    className: "remark-highlight"
  }, React.createElement(_components.pre, {
    className: "language-unknown"
  }, React.createElement(_components.code, {
    className: "language-unknown"
  }, "router.post(&#39;/orders&#39;, (req, res) =&gt; {\n// ...\n})\n\nrouter.post(&#39;/orders/:id/ship&#39;, (req, res) =&gt; {\n// ...\n})\n\nrouter.get(&#39;/orders/history&#39;, (req, res) =&gt; {\n// ...\n})"))));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
